import { lazy } from "react"

const LazyContracts = lazy(() => import("./pages/index.tsx"))

export const mainContractsPath = `/weispace/contracts`

export const contractsPaths = {
  home: mainContractsPath
}

const contractsRoutes = [
  {
    path: contractsPaths.home,
    element: <LazyContracts />
  }
]

export default contractsRoutes
