import { useAddLayoutToRoute } from "../utility/hooks/useAddLayoutToRoute"
import benefitsRoutes from "./benefits/router"
import challengesRoutes from "./challenges/router"
import faqRoutes from "./faq/router"
import { homeRoutes } from "./home/router"
import { TokenRoutes } from "./my-nfts/router"
import perfilRoutes from "./perfil-component/router"
import productsRoutes from "./products/router"
import transactionsRoutes from "./purshased-history/router"
import contentsRoutes from "./contents/router"
import campaignsRoutes from "./refer-and-win/router"
import stickerAlbumRoutes from "./sticker-album/router"
import contractsRoutes from "./contracts/router"

export const defaultWeispaceRoute = "/weispace"

export const weispaceRoutes = [
  ...homeRoutes,
  ...benefitsRoutes,
  ...faqRoutes,
  ...productsRoutes,
  ...contractsRoutes,
  ...TokenRoutes,
  ...perfilRoutes,
  ...transactionsRoutes,
  ...challengesRoutes,
  ...contentsRoutes,
  ...campaignsRoutes,
  ...stickerAlbumRoutes
]

export default useAddLayoutToRoute(weispaceRoutes)
